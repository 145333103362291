(function ($, Drupal, window, document) {

    'use strict';


    /**
     * MAIN MENU
     */
    Drupal.behaviors.marsadMainMenu = {
        attach: function (context, settings) {

            $('.toggle-menu').once('toggle-menu', function (event) {
                var block = $(this);

                var block_title = $('.block-title', block);
                var block_content = $('.menu-block-wrapper', block);

                var block_title_click_handler = function () {
                    block.toggleClass('active');
                };
                block_title.click(block_title_click_handler);

            });//end once
        }//end attach
    };//end behavior

    /**
     * FRONT PAGE
     */
    Drupal.behaviors.marsadFrontPage = {
        attach: function (context, settings) {

            $('#front-dis').once('front', function (event) {
                var front = $(this);

                var panes = $('.panel-pane', front);
                var row = panes.parent();

                $('.pane-content', panes).attr('data-equalizer-watch', 'panes');
                row.attr('data-equalizer', 'panes')
                    .attr('data-equalize-by-row', 'true');

                /*var latest = $('.view-latest.view-display-id-panel_pane_1', front);
                var latest_rows = $('.views-row', front);

                latest.attr('data-equalizer', 'latest')
                    .attr('data-equalize-by-row', 'true');
                latest_rows.attr('data-equalizer-watch', 'latest');*/

                row.foundation();

                /*var options = {
                    equalizeByRow: true,
                    equalizeOn: 'medium',
                    equalizeOnStack: true,
                };
                var eq = new Foundation.Equalizer(row, options);
                /!*row.foundation('getHeightsByRow',function(heights){
                    row.foundation('applyHeight', heights);
                });
                console.log(eq);

                Foundation.reInit(row);*!/*/


            });//end once
        }//end attach
    };//end behavior

    var pw = {'map': {}};
    pw.map = {
        beirut: {},
        init: function () {

            this.beirut = $('#beirut');

            if (this.beirut.length) {
                this.resize();

                this.leaflet.init();
                this.submit.init();

                $(window).resize(this.resize);

                $.ajaxSetup({
                    beforeSend: function () {

                        pw.map.leaflet.map.spin(true, {color: '#fc6b53'});
                    },
                    complete: function () {
                        pw.map.leaflet.map.spin(false);
                    },
                    error: function (x, e) {
                        pw.map.leaflet.map.spin(false);
                        if (x.status == 0) {
                            console.log(' Check Your Network.');
                        }
                        else if (x.status == 404) {
                            console.log('Requested URL not found.');

                        } else if (x.status == 500) {
                            console.log('Internel Server Error.');
                        } else {
                            console.log('Unknow Error.\n' + x.responseText);
                        }
                    }
                });
            }
        },

        submit: {
            geolocation: null,
            building_input: null,
            form: null,
            marker_style: {
                color: '#fc6b53',
                opacity: .75,
                radius: 0,
                weight: 2,
                fillColor: '#fc6b53',
                fillOpacity: .5,
            },
            marker: L.polygon([0, 0]),

            select_marker: function () {
                var map = pw.map.leaflet.map;

                var url = pw.map.buildings.url + 'lat=' + pw.map.submit.geolocation.lat + '&lng=' + pw.map.submit.geolocation.lng;
                $.get(url, function (data) {

                    if (data.length > 0) {
                        var value = data.pop();
                        if (!value.is_published) {
                            map.removeLayer(pw.map.submit.marker);
                            //_.each(data,function(value,key,list){
                            var _v = _.clone(value);
                            delete _v.shape;
                            value.shape.properties = _v;

                            pw.map.submit.building_input.val(value.shape.properties.url);
                            pw.map.submit.marker = L.geoJSON(value.shape, {
                                style: pw.map.submit.marker_style
                            }).addTo(map).bringToFront();
                            //});
                        }
                    }
                });
            },
            map_on_click: function (e) {

                var position = e.latlng;
                pw.map.submit.geolocation = position;
                pw.map.submit.select_marker();


            },


            init: function () {

                var map = pw.map.leaflet.map;

                this.building_input = $('.webform-component--onmap input');

                this.marker.setStyle(this.marker_style);//.addTo(map)


                //map.on("click", this.map_on_click);


            }
        },

        buildings: {
            //url: 'https://www.beirutevictions.org/buildings/?format=json',
            url: 'https://www.beirutevictions.org/building_at_point/?',
            objects: [],
            geojson: [],
            layer: {},
            added: [],
        },

        leaflet: {
            map: {},
            popup: new L.Popup(),
            layers: {'mapbox': {}, 'buildings': {},},

            buildings: {},

            loader: {},
            init: function () {

                this.map = L.map('beirut', {zoomControl: false}).setView([33.89, 35.51], 14);

                this.layers.mapbox = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/256/{z}/{x}/{y}?access_token={accessToken}',
                    {
                        maxZoom: 20, minZoom: 13,
                        //id: 'layalk/cj2hfj7mv000z2slprh2o01m8',
                        id: 'majdal/cjfw2lgnk3xe32spjev7g0cfs',
                        //accessToken: 'pk.eyJ1IjoibGF5YWxrIiwiYSI6ImNpbDlhaDJ4dzAwMzd2ZmtyYTdybTJqaDEifQ.4BPBAMHEF-xtr9cc1NErZg'
                        accessToken: 'pk.eyJ1IjoibWFqZGFsIiwiYSI6ImNpeXg1ZmNkZTAwMGEzM3BkMzlzM2w4YjAifQ.gYOMj7LrxRb29u-p83d_ng'
                    }).addTo(this.map);
                this.map.addControl(L.control.zoom({position: 'bottomleft'}))


                this.layers.buildings = L.layerGroup().addTo(this.map);


                var map_on_click = function (e) {
                    pw.map.leaflet.get_building(e);
                };
                this.map.on('click', map_on_click);


            },

            get_building: function (e) {
                var map = pw.map.leaflet.map;

                var position = e.latlng;
                //console.log(e);
                var building_style = {
                    color: "#000000",
                    opacity: 0,
                    fillColor: '#ffffff',
                    fillOpacity: 0,
                    radius: 0,
                    weight: 0,
                    className: 'building-bound'
                };
                var building_info_template = _.template('<% if (name) { %><div><strong>إسم البناء:</strong><%= name %></div><% } %><% if (year) { %><div><strong>سنة البناء:</strong><%= year %></div><% } %>');
                var building_info_options = {
                    /*offset:new L.Point(100,16),*/
                    direction: 'bottom',
                    className: 'building-tooltip'
                };


                var buildings_onEachFeature = function (feature, layer) {
                    var p = feature.properties;
                    // console.log(p);
                    $('#edit-submitted-building-info-building').val(p.name);
                    $('#edit-submitted-building-info-building-date-year').val(p.construction_year);
                    $('#edit-submitted-building-info-floors').val(p.floors);
                    $('#edit-submitted-building-info-residential-units').val(p.apartments);

                    var building_info = building_info_template({name: p.name, year: p.construction_year});
                    if (building_info.length > 0) {
                        layer.bindPopup(building_info, building_info_options);
                    }

                    layer.addTo(pw.map.leaflet.layers.buildings).bringToFront().openPopup();

                };
                var url = pw.map.buildings.url + 'lat=' + position.lat + '&lng=' + position.lng;
                $.get(url, function (data) {
                    if (data.length > 0) {
                        pw.map.buildings.objects.push(data);
                        _.each(data, function (value, key, list) {

                            var _v = _.clone(value);
                            delete _v.shape

                            value.shape.properties = _v;
                            if (!_.contains(pw.map.buildings.added, value.shape.properties.url)) {

                                pw.map.buildings.geojson.push(value.shape);
                                pw.map.buildings.added.push(value.shape.properties.url);
                                L.geoJSON(value.shape, {
                                    onEachFeature: buildings_onEachFeature,
                                    //pointToLayer:building_pointToLayer,
                                    style: building_style
                                });

                            }
                            //remove marker
                            map.removeLayer(pw.map.submit.marker);
                            pw.map.submit.building_input.val(value.shape.properties.url);
                            pw.map.submit.marker = L.geoJSON(value.shape, {
                                style: pw.map.submit.marker_style
                            }).addTo(map).bringToBack();

                        });
                    }


                });
            },


        },
        resize: function () {

            //pw.map.beirut.css('height',pw.window.height);
        }

    };

    function parseArabic(str) {
        return Number(str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
            return d.charCodeAt(0) - 1632; // Convert Arabic numbers
        })/*.replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
            return d.charCodeAt(0) - 1776; // Convert Persian numbers
        }) */);
    }

    /**
     * FORMS
     */
    Drupal.behaviors.marsadForms = {
        attach: function (context, settings) {


            $('.webform-client-form').once('hmform', function (event) {
                var form = $(this);
                form.attr('novalidate','novalidate');

                var map = $('#beirut', form);
                var onmap = $('.webform-component--onmap input', form);

                pw.map.init();

                //arabic numbers
                var reg_arNumbers = /[\u0660-\u0669]/;
                var form_number = $('.form-number', form).keyup(function () {
                    var field = $(this),
                        txt = field.val();
                    if (reg_arNumbers.test(txt)) {
                        field.val(parseArabic(txt));
                    }
                    else{
                        field.val(txt.replace(/\D/g, ''));
                    }
                });


            });//end once
        }//end attach
    };//end behavior

    //start behavior
    Drupal.behaviors.marsadFootnotes = {
    weight: 1,
    attach: function (context) {
      $('.node.view-mode-full', context).once('footnotes', function () {
        var node = $(this);
        if (Foundation.MediaQuery.is('medium')) {
          $('.see-footnote', node).each(function (i, o) {
            var $fn = $(this),
              $value = $fn.text(),
              $footnote = $($fn.attr('href'));

            // May be extended later on. (simply add ',div')
            var $parent = $fn.parent();
            if ($parent.length) {

              // Decide if we should add a left indicator to the generated
              // quote.
              /*var $opp = '';
               var bounds = $parent[0].getBoundingClientRect();
               if (bounds.top < window_height) {
               $opp = ' footnote-opposite';
               }*/

              // In the case of a pullquote inside any of the above
              // contained/nested elements
              var $elements = ['LI', 'UL', 'OL', 'TD', 'TR', 'TBODY', 'TABLE'];
              // Set a flag. Should we add the quote above the element or
              // inside it? pos set to 0 will be inside, 1 will be above.
              var pos = 0;
              while ($.inArray($parent.get(0).tagName, $elements) > -1) {
                pos = 1;
                if ($parent.parent().get(0).tagName === 'DIV') {
                  break;
                }
                $parent = $parent.parent();
              }
              // Apply conditional pullquote container styling.
              $parent.addClass('footnote-container');
              var $text = $footnote.html();

              var $second_parent = $('.footnote-contents', $parent);

              if ($second_parent.length === 0) {
                $second_parent = $('<div/>', {'class': 'footnote-contents'});
                if (pos === 0) {

                  $parent.append($second_parent);
                }
                else {

                  $parent.after($second_parent);
                }
              }
              var second_id = $fn.attr('href').replace('#', 'content_');
              var $second = $('<div/>', {
                'class': 'footnote-content ',
                'html': $text,
                id: second_id
              }).appendTo($second_parent);

              if (!$footnote.is(':visible')) {


                var fn_click = function (e) {
                  var fn = $(this);
                  var fn_content = $(fn.attr('href').replace('#', '#content_'));
                  fn_content.addClass('highlight')
                    .siblings('.footnote-content').removeClass('highlight');
                  var fn_position = fn.position();

                  fn_content.css('top', fn_position.top);
                  e.preventDefault();
                  $('html,body').animate({scrollTop: fn_content.offset().top - 20}, 500, function () {
                    fn_content.removeClass('highlight');
                  });
                };
                $fn.click(fn_click);


                var label_click = function (e) {
                  var target = $($(this).attr('href')).addClass('highlight');
                  $('html,body').animate({scrollTop: target.offset().top - 20}, 500, function () {
                    target.removeClass('highlight');
                  });

                  e.preventDefault();
                };
                $('.footnote-label', $second).click(label_click);
              }


            }


          });
          var contents = $('.footnote-contents', node);
          var prev_in_even = [];
          var prev_in_odd = [];
          var prev_in_opp = [];
          var prev = [];

          node.imagesLoaded(function () {
            contents.each(function (i, o) {
              var $o = $(o), parent = $o.parent();
              var parent_bounds = parent[0].getBoundingClientRect();
              var bounds = o.getBoundingClientRect();

              if (parent_bounds.top < $(window).height()) {
                //$o.addClass('footnote-opposite');
                if (prev_in_opp.length) {
                  prev = prev_in_opp;
                }
                prev_in_opp = $o;
              }
              else if (i % 2 === 1) {

                //$o.addClass('footnote-opposite');
                if (prev_in_odd.length) {
                  prev = prev_in_odd;
                }
                prev_in_odd = $o;
              }
              else {
                if (prev_in_even.length) {
                  prev = prev_in_even;
                }
                prev_in_even = $o;
              }
              if (prev.length) {
                var prev_bounds = prev[0].getBoundingClientRect();

                if (prev_bounds.bottom > bounds.top) {
                  $o.css('margin-top', prev_bounds.bottom - bounds.top);
                }
              }
            }).addClass('active');
          });

          //text direction for footnotes
          var arabic = /^[\u0600-\u06FF]+/g; //setting arabic unicode
          $('.footnote-content',contents).each(function (i, o) {
              var fn = $(o),
                fn_clone = fn.clone().find('.footnote-label').remove().end();
              var text = fn_clone.text().trim();
              fn_clone.remove();
              var is_arabic = text.match(arabic);
              if (is_arabic) {
                fn.attr('dir', 'rtl').css({'text-align':'right','direction':'rtl'});
              }
              else {
                fn.attr('dir', 'ltr').css({'text-align':'left','direction':'ltr'});
              }
            }
          );
        }
      });
      $('.see-footnote2', context).once('footnotes', function () {
        var $fn = $(this),
          $value = $fn.text(),
          $footnote = $($fn.attr('href'));

        // May be extended later on. (simply add ',div')
        var $parent = $fn.parent();
        if ($parent.length) {

          // Decide if we should add a left indicator to the generated quote.
          var $opp = '';
          var bounds = $parent[0].getBoundingClientRect();
          // if (bounds.top < window_height) {
          //   //$opp = ' footnote-opposite';
          // }
          // In the case of a pullquote inside any of the above contained/nested
          // elements
          var $elements = ['LI', 'UL', 'OL', 'TD', 'TR', 'TBODY', 'TABLE'];
          // Set a flag. Should we add the quote above the element or inside it?
          // pos set to 0 will be inside, 1 will be above.
          var pos = 0;
          while ($.inArray($parent.get(0).tagName, $elements) > -1) {
            pos = 1;
            if ($parent.parent().get(0).tagName === 'DIV') {
              break;
            }
            $parent = $parent.parent();
          }
          // Apply conditional pullquote container styling.
          $parent.addClass('footnote-container');
          var $text = $footnote.html();

          var $second_parent = $('.footnote-contents', $parent);
          if ($second_parent.length === 0) {
            $second_parent = $('<div/>', {'class': 'footnote-contents ' + $opp});
            if (pos === 0) {

              $parent.append($second_parent);
            }
            else {

              $parent.after($second_parent);
            }
          }
          var $second = $('<div/>', {
            'class': 'footnote-content ',
            'html': $text
          }).appendTo($second_parent);

          if (!$footnote.is(':visible')) {


            var return_false = function () {
              return false;
            };
            $fn.click(return_false);
            $('.footnote-label', $second).click(return_false);
          }
        }
      });
    }
  };
    //end behavior
})(jQuery, Drupal, this, document);


